import React from "react";

export default function Picture({ thumb, title, className }) {
  return (
    <picture>
      <source media="(min-width:1024px)" srcSet={thumb} />
      <img src={thumb} alt={title} className={className} loading="lazy" />
    </picture>
  );
}
