import React, { useEffect, useState } from "react";
import { isAuthenticated } from "../../services/AuthService";
export default function MainNav() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    const checkAuth = async () => {
      const currentUser = await isAuthenticated();
      setIsAuth(Boolean(currentUser));
    };
    checkAuth();
  });
  return isAuth ? (
    <div className="main-nav">
      <nav className="speeches lg:col-start-2 lg:col-span-3 lg:space-x-4">
        <a href="/">ECommerceDay Live 2023</a>
      </nav>
      <nav className="user lg:col-start-11 justify-end">
        <a href="/profile">Profilo</a>
      </nav>
    </div>
  ) : null;
}
