import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useUser } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { Api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import FieldRequired from "../FieldRequired";

export default function Profile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { user } = useUser();
  const navigate = useNavigate();
  const { id } = user?.user;
  const api = useMemo(() => new Api(), []);
  const [apiUser, setApiUser] = useState(null);
  const toastContainerId = {
    containerId: "profile",
  };
  useEffect(() => {
    const getData = async () => {
      const response = await api.getWithAuth(`/api/users/${id}`, user);
      const json = await response.json();
      if (json.errors) {
        toast.error(json.errors.map((e) => e.message).join("\n"), {
          ...toastContainerId,
        });
        return;
      }
      setApiUser(json);
      setValue("firstName", json.firstName);
      setValue("lastName", json.lastName);
    };
    getData();
  }, [id, user, api, setValue]);
  const onSubmit = async (data) => {
    const response = await api.patchWithAuth(`/api/users/${id}`, data, user);
    const json = await response.json();
    if (json.errors) {
      toast.error(json.errors.map((e) => e.message).join("\n"), {
        ...toastContainerId,
      });
      return;
    }
    toast.success(json.message, {
      autoClose: 2000,
      ...toastContainerId,
      onClose: function () {
        setTimeout(() => {
          navigate("/");
        }, 2100);
      },
    });
  };
  const uploadHandler = async (evt) => {
    const { files } = evt;
    for (const file of files) {
      const data = new FormData();
      data.append(
        "_payload",
        JSON.stringify({ title: file.name, alt: file.name }),
      );
      data.append("file", file);
      const response = await api.postFormDataWithAuth(`/api/media`, data, user);
      const json = await response.json();
      if (json.errors) {
        toast.error(json.errors.map((e) => e.message).join("\n"), {
          ...toastContainerId,
        });
        return;
      }
      const { id: docId } = json.doc;
      await api.patchWithAuth(
        `/api/users/${id}`,
        {
          photo: docId,
        },
        user,
      );
      toast.success(json.message, {
        autoClose: 2000,
        ...toastContainerId,
      });
    }
  };
  const itemTemplate = (file, props) => {
    console.log(file, props);
    return (
      <>
        <div
          className="file-info"
          style={{ backgroundImage: `url(${file.objectURL})` }}
        ></div>
        <Button
          type="button"
          className="fa fa-xmark"
          onClick={props.onRemove}
        />
      </>
    );
  };
  return (
    apiUser && (
      <>
        <div className="wrapper">
          <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-fields">
              <div className="form-fields__col">
                <label htmlFor="firstName">Nome</label>
                <input
                  type="text"
                  {...register("firstName", { required: true })}
                  {...(errors.firstName ? { className: "has-error" } : null)}
                />
                {errors.firstName && <FieldRequired />}
                <label htmlFor="lastName">Cognome</label>
                <input
                  type="text"
                  {...register("lastName", { required: true })}
                  {...(errors.lastName ? { className: "has-error" } : null)}
                />
                <label htmlFor="ChangePassword">Cambia Password</label>
                <input
                  type="password"
                  {...register("password", { required: false })}
                />
                {errors.lastName && <FieldRequired />}
              </div>
              <div className="form-fields__upload">
                {apiUser && apiUser.photo ? (
                  <div className="flex flex-col user-profile-picture">
                    <div
                      className="file-info"
                      style={{
                        backgroundImage: `url(${apiUser.photo.url})`,
                      }}
                    ></div>
                  </div>
                ) : null}

                <FileUpload
                  name="media"
                  chooseLabel="Scegli foto profilo"
                  cancelLabel="Annulla"
                  uploadLabel="Carica"
                  customUpload={true}
                  uploadHandler={uploadHandler}
                  accept="image/*"
                  itemTemplate={itemTemplate}
                  emptyTemplate={<></>}
                />
              </div>
            </div>
            <button
              type="submit"
              className="button button-primary button__centered mt-4 lg:mt-0"
            >
              Aggiorna profilo
            </button>
          </form>
        </div>
        <ToastContainer
          containerId={toastContainerId.containerId}
          enableMultiContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    )
  );
}
