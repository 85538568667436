import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ReactHlsPlayer from "react-hls-player";
import { useUser } from "../../context/UserContext";
import { toast } from "react-toastify";
import { Api } from "../../services/api";
import "react-toastify/dist/ReactToastify.min.css";
import { Helmet } from "react-helmet";

export default function Detail() {
  let { id } = useParams();
  const sessionUri = "/api/conference-sessions/" + id;
  const { user } = useUser();
  const api = useMemo(() => new Api(), []);
  const [video, setApiUser] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const response = await api.getWithAuth(sessionUri, user);
      const json = await response.json();
      if (json.errors) {
        toast.error(json.errors.map((e) => e.message).join("\n"));
        return;
      }
      setApiUser(json);
    };
    getData();
  }, [user, api]);

  return (
    video && (
      <div className="wrapper">
        <Helmet>
          <title>{video.title} | ECommerceDayLive 2023</title>
        </Helmet>
        <ReactHlsPlayer
          src={video.videoURL} // The last video updated in the backend having type live is played
          autoPlay={true}
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
    )
  );
}
