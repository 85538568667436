import React, { useEffect, useMemo, useState } from "react";
import { useUser } from "../../context/UserContext";
import { Api } from "../../services/api";
import Card from "./Card";

export default function CardList({ title }) {
  const { user } = useUser();
  const api = useMemo(() => new Api(), []);
  const [conferenceSessions, setApiUser] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await api.getWithAuth(
        `/api/conference-sessions?where[type][equals]=vod&sort=-updatedAt`,
        user,
      );
      const json = await response.json();
      setApiUser(json);
    };
    getData();
  }, [user, api]);

  return (
    conferenceSessions && (
      <React.Fragment>
        <div className="wrapper main-content">
          {conferenceSessions.docs.map((item) => (
            <div className="list" key={item.id}>
              <Card item={item} />
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  );
}
