const Partners = () => {
  return (
    <div className="partner-wrap">
      <div className="partners">
        <div className="partner-list main">
          <h2>Main partner</h2>
          <div className="row">
            <img src="/assets/logos/main/hcltech.png" />
          </div>
        </div>
        <div className="partner-list platinum">
          <h2>Platinum partner</h2>
          <div className="row">
            <img src="/assets/logos/platinum/newesis.png" />
          </div>
        </div>
        <div className="partner-list gold">
          <h2>Gold partner</h2>
          <div className="row first">
            <img src="/assets/logos/gold/sella.png" />
            <img src="/assets/logos/gold/arcus.png" />
            <img src="/assets/logos/gold/gbs.png" />
            <img src="/assets/logos/gold/planet.png" />
            <img src="/assets/logos/gold/naturalindex.png" />
          </div>
          <div className="row">
            <img src="/assets/logos/gold/dway.png" />
            <img src="/assets/logos/gold/convertix.png" />
            <img src="/assets/logos/gold/reply_cluster.png" />
            <img src="/assets/logos/gold/sitecore.png" />
            <img src="/assets/logos/gold/reply_like.png" />
          </div>
        </div>
      </div>
      <div className="partners">
        <div className="partner-list organizers last">
          <h2>Evento organizzato da</h2>
          <div className="row">
            <img src="/assets/logos/organized_by/jusan.png" />
            <img src="/assets/logos/organized_by/turin_ecommerceday.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
