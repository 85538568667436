import React, { useState, useEffect, createContext } from "react";
import Login from "../components/Login";
import { isAuthenticated } from "../services/AuthService";

const UserContext = createContext({
  user: null,
  setUser: () => {},
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = await isAuthenticated();
      if (cuser === null) {
        localStorage.setItem("user", "");
        cuser = "";
      }

      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, []);

  return (
    <UserContext.Provider
      value={{ user: currentUser, setUser: setCurrentUser }}
    >
      <div id="main" className="main-content">
        {currentUser?.token ? children : <Login />}
      </div>
    </UserContext.Provider>
  );
};

export default UserContext;

export const useUser = () => {
  return React.useContext(UserContext);
};
