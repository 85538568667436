import React from "react";
import ReactDOM from "react-dom/client";
import "./App.scss";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import MainNav from "./components/MainNav";
import Profile from "./components/Profile";
import Video from "./components/Video";
import { socket, SocketContext } from "./utils/socket";
import AlertModal from "./components/Alert";
import Detail from "./components/Page/Detail";
import CardList from "./components/Card/CardList";
import Partners from "./components/Partners";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <SocketContext.Provider value={socket}>
          <MainNav />
          <AlertModal />
          <UserProvider>
            <App />
            <Video />
            <Partners />
            <CardList />
          </UserProvider>
        </SocketContext.Provider>
      </>
    ),
  },
  {
    path: "/profile",
    element: (
      <>
        <SocketContext.Provider value={socket}>
          <MainNav />
          <AlertModal />
          <UserProvider>
            <Profile />
          </UserProvider>
        </SocketContext.Provider>
      </>
    ),
  },
  {
    path: "/session/:id",
    element: (
      <>
        <SocketContext.Provider value={socket}>
          <MainNav />
          <AlertModal />
          <UserProvider>
            <Detail />
            <Partners />
            <CardList />
          </UserProvider>
        </SocketContext.Provider>
      </>
    ),
  },
]);

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Cannot find root element");
}
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <div className="grid grid-cols-1 lg:grid-cols-12 bg-white border-gray-300 border-1 border-b">
      <a href="/" className="lg:col-start-2 lg:col-span-10 flex justify-center">
        <img src="https://ecommerce-day-test.myshopify.com/cdn/shop/files/ecommercay_logo.png?height=80&v=1678723101" />
      </a>
    </div>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
