import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { login, saveUser } from "../../services/AuthService";
import FieldRequired from "../FieldRequired";
export default function Login() {
  const toastContainerId = {
    containerId: "login",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const json = await login(data.email, data.password);
    if (json.errors) {
      toast.error(json.errors.map((e) => e.message).join("\n"), {
        ...toastContainerId,
      });
      return;
    }
    saveUser(json);
    toast.success(json.message, {
      ...toastContainerId,
      autoClose: 2000,
      onClose: function () {
        setTimeout(() => {
          window.location.reload();
        }, 2100);
      },
    });
  };
  return (
    <div className="wrapper">
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="email" className="mt-4">
          Email
        </label>
        <input
          type="email"
          {...register("email", { required: true })}
          {...(errors.email ? { className: "has-error" } : null)}
        />
        {errors.email && <FieldRequired />}
        <label htmlFor="password" className="mt-4">
          Password
        </label>
        <input
          type="password"
          {...register("password", { required: true })}
          {...(errors.password ? { className: "has-error" } : null)}
        />
        {errors.password && <FieldRequired />}
        <button
          type="submit"
          {...(errors.email || errors.password ? { disabled: true } : null)}
          className="mt-4 button button-primary"
        >
          Login
        </button>
      </form>
      <ToastContainer
        containerId={toastContainerId.containerId}
        enableMultiContainer
        position="bottom-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
