import React, { useEffect, useMemo, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useUser } from "../../context/UserContext";
import { toast } from "react-toastify";
import { Api } from "../../services/api";
import Hls from "hls.js";

import "react-toastify/dist/ReactToastify.min.css";

export default function Video() {
  const { user } = useUser();
  const playerRef = React.useRef();
  const api = useMemo(() => new Api(), []);
  const [video, setApiUser] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const response = await api.getWithAuth(
        `/api/conference-sessions?where[type][equals]=live&sort=-updatedAt`,
        user,
      );
      const json = await response.json();
      if (json.errors) {
        toast.error(json.errors.map((e) => e.message).join("\n"));
        return;
      }
      setApiUser(json);
    };
    getData();
  }, [user, api]);

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }
    if (!video) {
      return;
    }
    const newHls = new Hls({});
    newHls.autoLevelCapping = 6000000;
    newHls.loadSource(video.docs[0].videoURL);
    newHls.attachMedia(playerRef.current);
    // console.log(newHls.autoLevelCapping);
  });
  return (
    video && (
      <div className="wrapper">
        <ReactHlsPlayer
          playerRef={playerRef}
          // src={video.docs[0].videoURL} // The last video updated in the backend having type live is played
          autoPlay={true}
          controls={true}
          hlsConfig={{
            hls: {
              autoLevelCapping: 6000000,
            },
          }}
          width="100%"
          height="auto"
        />
      </div>
    )
  );
}
