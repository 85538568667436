import React from "react";
import { Link } from "react-router-dom";
import Picture from "../Picture/Picture";
import ImagePlaceholder from "../Picture/ImagePlaceholder";

const Card = ({ item }) => {
  const { pathname } = window.location;
  const cardLink = `/session/${item.id}`;
  let linkClassName;
  if (pathname === cardLink) {
    linkClassName = "selected";
  }
  return (
    <Link to={cardLink} className={linkClassName} reloadDocument>
      <div className="picture">
        {item.thumbURL ? (
          <Picture
            thumb={item.thumbURL}
            title={item.title}
            className="rounded-md"
          />
        ) : (
          <ImagePlaceholder title={item.title} />
        )}
      </div>
      <h2>{item.title}</h2>
    </Link>
  );
};

export default Card;
