import { Api } from "./api";
const api = new Api();
export const login = async (email, password) => {
  const res = await api.post("/api/users/login", {
    email,
    password,
  });
  return await res.json();
};
export const saveUser = (json) => {
  const currentTime = new Date();
  currentTime.setSeconds(currentTime.getSeconds() + 7200);
  localStorage.setItem(
    "user",
    JSON.stringify({
      user: json.user,
      token: json.token,
      exp: json.exp,
      expiration: currentTime.getTime(),
    }),
  );
};
const resetStorage = () => {
  localStorage.setItem("user", "");
  return null;
};
export const isAuthenticated = async () => {
  const user = localStorage.getItem("user");
  if (!user) {
    return null;
  }
  const savedUser = JSON.parse(user);
  if (!savedUser.expiration) {
    return resetStorage();
  }
  if (savedUser.expiration < new Date().getTime()) {
    return resetStorage();
  }
  return savedUser;
};
